import { ActionContext } from 'vuex';
import ApiService from "../../services/api.service";

interface State {
    selection: string | null;
}

export default {
    state: (): State => ({
        selection: null
    }),
    actions: {
        async getSelection(ctx: any) {
            const res = await ApiService.get('acceleratorselection')
            ctx.commit('setSelection', res.data)
        },
        async clearSelection(ctx: ActionContext<State, any>) {
            ctx.commit('setSelection', null);
        }
    },
    mutations: {
        setSelection(state: State, role: string) {
            state.selection = role;
        }
    },
    getters: {
        selection(state: State): string | null {
            return state.selection;
        }
    }
}