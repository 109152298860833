import { ActionContext } from 'vuex';

interface State {
    selectionRole: string | null;
}

export default {
    state: (): State => ({
        selectionRole: localStorage.getItem('selectionRole') || null
    }),
    actions: {
        async setSelectionRole(ctx: ActionContext<State, any>, role: string) {
            localStorage.setItem('selectionRole', role);
            ctx.commit('setSelectionRole', role);
        },
        async clearSelectionRole(ctx: ActionContext<State, any>) {
            localStorage.removeItem('selectionRole');
            ctx.commit('setSelectionRole', null);
        }
    },
    mutations: {
        setSelectionRole(state: State, role: string) {
            state.selectionRole = role;
        }
    },
    getters: {
        selectionRole(state: State): string | null {
            return state.selectionRole;
        }
    }
}